/* Container and basic layout */
.container {
    padding: 20px;
    width: 100%;
    max-width: 80%; /* Prevents container from exceeding viewport width */
    margin: 0 auto;
}

.coin-header-line {
    width: 100%; /* Take up full width */
    height: 2px; /* 2px height */
    background-color: black; /* Black color */
    margin-top: 10px; /* Optional: Add some spacing if needed */
}

body {
    padding-top: 80px; /* Same height as the navbar */
    overflow-x: hidden;
  }


h1 {
    color: white;
    text-align: center;
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

p {
    margin: 0.5rem 0;
    line-height: 1.5;
}

.loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Coin card styles */
.coin-card {
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.coin-card:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

/* Coin header: Contains both coin info (name & image) and icons */
.coin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap; /* Prevent wrapping even on small screens */
}

/* Flex container to align coin name and image in a row */
.coin-info {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 1;
    flex-wrap: nowrap; /* Prevents elements from wrapping */
}

/* Coin title */
.coin-title {
    color: #1A202C;
    font-weight: bold;
    font-size: 1.75rem;
    margin: 0;
    line-height: 1;
    display: inline-block;
}

/* Coin image */
.coin-image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
    vertical-align: middle;
}

.coin-info * {
    vertical-align: middle;
}

/* Coin icons section */
.coin-icons {
    display: flex;
    gap: 15px;
}

/* Tooltip styles for icons */
.icon-with-tooltip {
    position: relative;
    cursor: pointer;
}

.icon-with-tooltip .tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    white-space: nowrap;
}

.icon-with-tooltip:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* Chart container and responsiveness */
.chart-container {
    margin-top: 10px;
    display: flex;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.chart-wrapper {
    flex-shrink: 0;
    width: 24%;
    margin-right: 10px;
    padding-right: 2px;
}

@media (max-width: 768px) {
    .chart-wrapper {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden; /* Prevent horizontal overflow */
    }

    .chart-container {
        margin-top: 10px;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    .coin-header-line {
        width: 100%; /* Take up full width */
        height: 2px; /* 2px height */
        background-color: black; /* Black color */
        margin-top: 10px; /* Optional: Add some spacing if needed */
    }
    
}

/* Stats section for charts */
.stats-section {
    margin-top: 10px;
}

/* Global styles for links */
a {
    color: #3182CE;
}

/* Loader container for loading spinner */
.loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
    .coin-header {
        flex-direction: row; /* Maintain row layout */
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap; /* Ensure the layout stays consistent */
    }

    .coin-header-line {
        width: 100%; /* Take up full width */
        height: 2px; /* 2px height */
        background-color: black; /* Black color */
        margin-top: 10px; /* Optional: Add some spacing if needed */
    }

    .coin-info {
        flex-direction: row;
        justify-content: flex-start; /* Keep image and text aligned horizontally */
        align-items: center;
        gap: 10px;
        flex-wrap: nowrap; /* Prevent wrapping */
        width: auto;
    }

    .coin-title {
        font-size: 1.5rem; /* Slightly adjust size for smaller screens */
    }

    .coin-image {
        width: 20px;
        height: 20px;
    }

    h2 {
        font-size: 1rem;
    }

    /* Adjust icon spacing for mobile */
    .coin-icons {
        gap: 10px;
    }
}

/* Even smaller screens adjustments (optional) */
@media (max-width: 480px) {
    .coin-title {
        font-size: 1.25rem;
    }

    .container {
        padding: 20px;
        width: 100%;
        max-width: 80%; /* Prevents container from exceeding viewport width */
        margin: 0 auto;
    }

    body {
        padding-top: 80px; /* Same height as the navbar */
        overflow-x: hidden;
      }

    .coin-image {
        width: 18px;
        height: 18px;
    }

    .coin-icons {
        gap: 8px;
    }

    h2 {
        font-size: 0.9rem;
    }
}