/* General container style */
.translate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    gap: 20px;
}

.content-editable.placeholder::before {
    content: 'Enter text to translate...';
    color: #aaa;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 10px;
}

/* Card styles for input and output sections */
.translate-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 80%;
}

.translate-card:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

/* Specific styles for input and output sections */
.translate-input-box {
    background-color: #f5f5f5;
    margin-right: 20px;
}

.translate-output-box {
    background-color: #f5f5f5;
}

/* Button styling */
.translate-button {
    align-self: flex-start;
    background-color: #3182CE;
    color: #fff;
}

/* Language selection buttons */
.lang-button {
    padding: 10px 20px;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 10px;
    display: inline-block;
    flex: 1;
}

/* Hover and active states */
.lang-button:hover {
    background-color: #3182CE;
    color: #fff;
}

.lang-button.active {
    background-color: #0056b3;
    color: #fff;
}

/* Language selector */
.language-select-form {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

/* Translation output box styling */
.translation-input {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex: 1;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}

/* Translation output box styling */
.translation-output {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex: 1;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}

/* Output text styling */
.translation-text {
    word-wrap: break-word;
    white-space: pre-wrap;
    min-height: 300px;
    overflow-y: auto;
}

/* Input field styling */
.translate-input-field {
    word-wrap: break-word;
    white-space: pre-wrap;
    background-color: #fff;
    min-height: 300px;
    overflow-y: auto;
}

/* Buttons for copy and speech actions */
.translate-action-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .translate-container {
        flex-direction: column;
        align-items: stretch;
    }

    .translate-card {
        margin-right: 0;
    }
}